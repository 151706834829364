import {axios} from "../../utils";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import UploadPhoto from "../../images/upload-photo.svg";
import ArrowRight from "../../images/arrow-square-right.svg"
import ArrowDown from "../../images/arrow-square-down.svg"

export default function View(){
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState({});
    const [toggle, setToggle] = useState({});
    const labels = [
        {
            id:'sales-data',label:'Sales Data',
            children: [
                {id:'sales-data',label:'Sales Data'},
            ]
        },
        {
            id:'marketing-tool', label: 'Marketing Tool',
            children: [
                {id:'voucher',label:'Voucher'},
                {id:'promos',label:'Promos'},
                {id:'referral-program',label:'Referral Program'},
            ]
        },
        {
            id:'wallet', label: 'Wallet Eco System',
            children: [
                {id:'mother-wallet',label:'Mother Wallet'},
                {
                    id:'conversion-settings',label:'Conversion Settings',
                    children: [
                        {id:'topup-issuance-rate', label: 'Top Up Issuance Rate'},
                        {id: 'reward-issuance-rate', label: 'Reward Issuance Rate'},
                        {id:'redemption-rate', label: 'Redemption Rate'},
                    ]
                },
                {id:'top-up',label:'Top Up'},
                {id:'reward-settings',label:'Reward Settings'},
                {id:'voucher-wallet-settings',label:'Voucher Wallet Settings'},
            ]
        },
        {
            id:'menu-database', label: 'Menu Database',
            children: [
                {id: 'menu-database', label: 'Menu Database'},
            ]
        },
        {
            id:'branches', label: 'Branches',
            children: [
                {id:'branches', label: 'Branches'},
            ]
        },
        {
            id:'staffs', label: 'Staffs',
            children: [
                {id:'staffs', label: 'Staffs'},
            ]
        },
        {
            id:'reports', label: 'Reports',
            children: [
                {
                    id:'transaction-report', label: '',
                    children: [
                        {id:'daily-payments-transactions', label: ''},
                        {id:'month-to-date-payments-transactions', label: ''},
                        {id:'daily-topup-transactions', label: ''},
                        {id:'month-to-date-topup-transactions', label: ''},
                    ]
                },
                {
                    id: 'balance-report', label: '',
                    children: [
                        {id: 'mother-wallet-balance', label: ''},
                        {id: 'as-of-today-user-balances', label: ''},
                        {id: 'as-of-today-branch-balances', label: ''},
                    ]
                },
                {
                    id: 'vouchers', label: '',
                    children: [
                        {id: 'all-vouchers', label: ''},
                        {id: 'redeemed-vouchers', label: ''},
                    ]
                }
            ]
        },
        {
            id:'roles', label: 'Roles & Permissions',
            children: [
                {id:'roles', label: 'Roles & Permissions'}
            ]
        },
    ];
    const getUser = async()=>{
        axios.get(`/roles/${searchParams.get('id')}`)
            .then((resp)=>setUser(resp.data))
            .catch(err=>toast.error(err.response?.data || err.message))
    }

    const onToggle = id=>{
        setToggle((prev)=>({...prev, [id]: !prev[id]}));
    }

    useEffect(()=>{
        getUser();
    }, []);
    
    return <>
    <div className="flex justify-between">
        <div className="flex justify-start space-x-2">
            <div className="text-center cursor-pointer mb-[-25px]">
                <img src={UploadPhoto}/>
                <div className="w-[10px] relative text-gray-400 text-sm top-[-55px] left-[13px]">Upload &nbsp;Photo</div>
            </div>
            <div>
                <h1 className="font-semibold text-2xl">{user.first_name} {user.last_name}</h1>
                <span className="text-green">{user.job_title} <span className="text-gray">{user.email}</span></span>
            </div>
        </div>
        <div>
            <Link to="/roles" class="inline rounded-full border-green text-green py-1 px-10" type="button">
                BACK
            </Link>
        </div>
    </div>
    <table className="table-auto w-[100%]">
        <thead>
            <tr>
                <th>Permission</th>
                <th>View</th>
                <th>Extract</th>
                <th>Create</th>
                <th>Edit</th>
                <th>Archive</th>
            </tr>
        </thead>
        <tbody>
            {
                labels.map((l, i)=>{
                    return <>
                        <tr key={i} onClick={()=>onToggle(l.id)} className={`${toggle[l.id] && 'bg-darkgreen'} cursor-pointer`}>
                            <td colSpan={6}>
                                <div className="flex justify-between">
                                    <span>{l.label}</span>
                                    <img src={toggle[l.id] ? ArrowDown : ArrowRight}/>
                                </div>
                            </td>
                        </tr>
                            {
                                toggle[l.id] && l.children?.map((c,i)=><tr key={i}>
                                        <td>{c.label}</td>
                                        <td className="text-center"><input type="checkbox"/></td>
                                        <td className="text-center"><input type="checkbox"/></td>
                                        <td className="text-center"><input type="checkbox"/></td>
                                        <td className="text-center"><input type="checkbox"/></td>
                                        <td className="text-center"><input type="checkbox"/></td>
                                </tr>)
                            }
                    </>
                })
            }
        </tbody>
    </table>
    </>
}