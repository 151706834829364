import React, {Component, useEffect, useState} from "react";
import {axios} from '../utils';
import Logo from "../images/login-logo.svg";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify"
import useUserStore from "../stores/user";
import Countdown from "react-countdown";

class InputOTP extends Component {
    state = {otp: ''};
    handleChange = (otp)=>this.setState({otp})
    componentDidUpdate(){
        const {otp} = this.state;
        this.props.setOTP(otp)
    }
    render(){
        return <OTPInput inputType="tel" onChange={this.handleChange} containerStyle="pt-6" value={this.state.otp} inputStyle={{fontSize:'2rem',color:'#75894B',borderColor:"#E5E7EB",width:59,height:67,marginLeft:'1rem', marginRight:'1rem',borderRadius:'0.25rem'}} numInputs={6} renderInput={(props)=><input {...props} type="number"/>}/>
    }
}

const OTP = ({email, navigate, setPage}) => {
    const [otp, setOTP] = useState('');
    const setUser = useUserStore(state=>state.setUser);
    const [reset, setReset] = useState();

    const onSubmit = async ()=>{
        try{
            await axios.post('login', {email, otp})
            setUser({email});
            navigate('/')
        }catch(err){
            toast.error(err.response?.data || err)
        }
    }

    const onReset = async()=>{
        const resp = await axios.post('login', {email});
        toast(resp.data)
        setReset(true)
    }

    return <div className="pt-5 grid text-center">
        <p className="m-auto text-xl w-[358px]">Please enter the verification code sent to {email}</p>
        <div className="m-auto">
            <InputOTP setOTP={setOTP} email={email} navigate={navigate} />
            <p className=" pt-3 text-gray font-normal">
                Did'nt work? 
                {!reset && <span onClick={onReset} className={`text-green text-base cursor-pointer ml-1 mr-1 ${reset && 'opacity-50'}`}>
                    Send me another code
                </span>}
                    {reset && <span className="text-green ml-1">Retry in <Countdown date={Date.now()+120000} renderer={({minutes, seconds, completed})=>{
                            if(completed) setReset(false);
                            return <span>{String(minutes).padStart(2, 0)}:{String(seconds).padStart(2, 0)}</span>
                    }} /> seconds</span>}
            </p>
            <button onClick={onSubmit} disabled={`${otp}`.length!=6} className="mt-16 p-3 rounded-full bg-green disabled:bg-gray-400 text-white w-[406px] h-[44px]">LOGIN</button>
            <p onClick={()=>setPage('form')} className="text-green mt-7">Change email</p>
        </div>
    </div>
}

const Form = ({email, setPage, setEmail})=>{

    const handleChange = (e)=>{
        setEmail(e.target.value)
    }
    const onSubmit = async ()=>{
        try{
            const resp = await axios.post('login', {email});
            toast(resp.data)
            setPage('otp')
        }catch(err){
            toast.error(err.response?.data || err);
        }
    }   

    return <div className="pt-16 grid">
        <label style={{fontWeight:600,fontSize:16}}>Email</label>
        <input name="email" value={email} onChange={handleChange} style={{borderColor:"#E5E7EB"}} type="text" className="rounded"/>
        <button disabled={!email} onClick={onSubmit} className="mt-16 p-3 rounded-full bg-green disabled:bg-gray-400 text-white">LOGIN</button>
    </div>
}

export default function Login(){
    const [page, setPage] = useState('form');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();


    useEffect(()=>{
        axios.post('/')
            .then((resp)=>{
                if(resp.data) navigate('/')
            })
    }, [])

    function render(){
        if(page=='form'){
            return <Form email={email} setPage={setPage} setEmail={setEmail}/>;
        }else{
            return <OTP email={email} navigate={navigate} setPage={setPage}/>
        }
    }

    return <div className="flex h-screen" style={{backgroundColor:"#F5F5F5"}}>
        <div className="m-auto bg-white p-16 rounded">
            <img src={Logo} width={414} className="h-[176px] m-auto"/>
            {render()}
        </div>
    </div>
}